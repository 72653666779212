<template>
    <section aria-labelledby="announcements-title" class="relative min-h-[200px] shadow rounded-lg bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>
        <div class="rounded-lg overflow-hidden">
            <div class="p-5">
                <div class="flex items-center justify-between">
                    <h2 class="font-semibold text-2xl dark:text-gray-300">Kurjeri</h2>
                    <h2 class="text-sm text-gray-900 dark:text-gray-300">šobrīd aktīvas piegādes</h2>
                </div>

                <div class="flow-root mt-6 bg-white dark:bg-gray-750 rounded-lg p-3">
                    <ul role="list" class="-my-5 divide-y divide-gray-200 dark:divide-gray-600">
                        <template v-if="couriers">
                            <template v-for="(courier, index) in couriers" :key="`${courier.user}_${index}`">
                                <li class="py-4 relative">
                                    <router-link :to="`/courier_applications?status_id=2&direction=&user_id=${courier.id}&page=1&q=`" class="absolute top-0 left-0 w-full h-full z-10"></router-link>
                                    <div class="relative flex items-center justify-between">
                                        <h3 class="text-sm text-gray-800 dark:text-gray-200" :class="courier.is_logged_in_user ? 'font-bold dark:text-white' : ''">
                                            {{courier.user}}
                                        </h3>
                                        <p class="mt-1 text-sm text-main4 font-bold px-5">{{courier.active_applications}}</p>
                                    </div>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;
import { defineAsyncComponent } from 'vue'

const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "CouriersWidget",
    components: {
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    data() {
        return {
            couriers: null,
            loading: false,
        }
    },
    created() {
        this.loading = true
        axios
            .get(baseUrl + "/api/dashboard_components/" + this.component.id)
            .then((response) => {
                this.couriers = response.data
                this.loading = false
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("destroyToken");
                }
                this.loading = false
            });
    }
}
</script>

<style>

</style>